body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbars for all elements */
html,
body {
  overflow: auto; /* Allow scrolling */
  scrollbar-width: none; /* For Firefox */
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}
